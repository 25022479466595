@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  src: url('assets/fonts/Montserrat/Montserrat-Regular.ttf');
}

html,
body {
  overflow-x: hidden;
  font-family: Montserrat, 'sans-serif';
  color: theme('colors.dark.DEFAULT');
  scroll-behavior: smooth;
}

.grecaptcha-badge {
  z-index: 1000;
}

.form-control {
  &:focus {
    border-color: #fe7f2d;
    box-shadow: 0 0 0 0.2rem #fe812d3b;
  }
}

.section {
  position: relative;
  background: #fff;
}

h1 {
  @apply text-4xl font-bold;
}

h2 {
  @apply text-3xl font-bold;
}

h3 {
  @apply text-2xl font-bold;
}

h4 {
  @apply text-xl font-bold;
}

a-button {
  fa-icon {
    @apply text-xs;
  }
}

.code-block-wrapper:has(pre):has(code) {
  position: relative;

  button {
    position: absolute;
    right: 0;
    top: 0;
    width: 32px;
    @apply m-2 rounded-md border border-solid border-gray-700 bg-gray-100 p-2 transition-all;

    svg {
      @apply fill-gray-700 transition-all;
    }

    &:hover {
      @apply border-primary;

      svg {
        @apply fill-primary transition-all;
      }
    }
  }

  pre {
    @apply overflow-x-auto rounded-md bg-gray-100 p-4;
    margin: 0 !important;
  }
}
